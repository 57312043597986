import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useStyles } from './RegistrationPageTheme';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useAuthentication } from '../Context/AuthenticationContext';
import { useHistory } from 'react-router-dom';
import { useDialog } from '../Context/DialogContext';
import PublicRoutes from '../Constants/PublicRoutes';
import { Alert } from '@material-ui/lab';

const initialFromState = {
  email: '',
  userName: '',
  password: '',
  passwordConfirmation: '',
  firstName: '',
  lastName: '',
};

export default function RegistrationPage() {
  const classes = useStyles();
  const { register } = useAuthentication();
  const [registrationError, setRegistrationError] = useState([]);
  const history = useHistory();
  const { displayDialog, dismissDialog } = useDialog();

  const RegistrationValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Must be a valid email.')
      .required('This field is required.'),
    userName: yup.string().required('This field is required.'),
    password: yup.string().required('This field is required.'),
    passwordConfirmation: yup.string().required('This field is required.'),
    firstName: yup.string().required('This field is required.'),
    lastName: yup.string().required('This field is required.'),
  });

  const submitData = async (values) => {
    const { email, userName, password, firstName, lastName } = values;

    const requestData = {
      email: email,
      userName: userName,
      password: password,
      firstName: firstName,
      lastName: lastName,
    };

    try {
      await register(requestData);
      popRegistrationCompleteModal();
    } catch (err) {
      setRegistrationError(
        Array.isArray(err.response.data)
          ? err.response.data
          : [err.response.data]
      );
    }
  };

  const popRegistrationCompleteModal = () => {
    const modalButtons = [
      {
        title: 'Login',
        action: () => {
          history.push(PublicRoutes.Login);
          dismissDialog();
        },
      },
    ];
    displayDialog(
      'Congratulations!',
      `You have successfully registered. \n An email has been sent to the given email with a link to activate your account.`,
      modalButtons
    );
  };

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <Box className={classes.signUp}> Sign up</Box>
        </Typography>
        <Formik
          enableReinitialize={true}
          initialValues={initialFromState}
          validationSchema={RegistrationValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await submitData(values);
          }}
        >
          {({ errors, handleChange, touched, values, isSubmitting }) => (
            <Form>
              <div className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      variant="outlined"
                      fullWidth
                      id="email"
                      label="Email"
                      style={{
                        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
                      }}
                      onChange={handleChange}
                      error={errors.email && touched.email}
                      value={values.email}
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="userName"
                      variant="outlined"
                      fullWidth
                      id="userName"
                      label="Username"
                      style={{
                        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
                      }}
                      onChange={handleChange}
                      error={errors.userName && touched.userName}
                      value={values.userName}
                      helperText={
                        errors.userName && touched.userName
                          ? errors.userName
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      variant="outlined"
                      fullWidth
                      id="password"
                      label="Password"
                      type="password"
                      style={{
                        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
                      }}
                      onChange={handleChange}
                      error={errors.password && touched.password}
                      value={values.password}
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="passwordConfirmation"
                      variant="outlined"
                      fullWidth
                      id="passwordConfirmation"
                      label="Password Confirmation"
                      type="password"
                      style={{
                        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
                      }}
                      onChange={handleChange}
                      error={
                        errors.passwordConfirmation &&
                        touched.passwordConfirmation
                      }
                      value={values.passwordConfirmation}
                      helperText={
                        errors.passwordConfirmation &&
                        touched.passwordConfirmation
                          ? errors.passwordConfirmation
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      style={{
                        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
                      }}
                      onChange={handleChange}
                      error={errors.firstName && touched.firstName}
                      value={values.firstName}
                      helperText={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      style={{
                        boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
                      }}
                      onChange={handleChange}
                      error={errors.lastName && touched.lastName}
                      value={values.lastName}
                      helperText={
                        errors.lastName && touched.lastName
                          ? errors.lastName
                          : null
                      }
                    />
                  </Grid>
                </Grid>
                <br />
                {registrationError.length > 0 && (
                  <Alert severity="error">
                    {registrationError.map((x, idx) => (
                      <p key={idx}>{x}</p>
                    ))}
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Sign up
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
