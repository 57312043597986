import React, { useCallback, useContext, useEffect, useState } from 'react';
import API from '../API/API';
import jwt_decode from 'jwt-decode';
import { AuthenticationStateUtility } from '../Utils/AuthenticationStateUtility';

export const SessionState = Object.freeze({
  LOGGED_IN: Symbol('LOGGED_IN'),
  LOGGED_OUT: Symbol('LOGGED_OUT'),
  UNKNOWN: Symbol('UNKNOWN'),
});

const AuthenticationContext = React.createContext({
  sessionState: SessionState.UNKNOWN,
  user: null,
  authenticate: () => {},
  logout: () => {},
  register: () => {},
});
const { Provider, Consumer } = AuthenticationContext;

/**
 * React context handling registration, authentication, and user management
 */
export const AuthenticationProvider = ({ children }) => {
  const [sessionState, setSessionState] = useState(SessionState.UNKNOWN);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setSessionState(SessionState.UNKNOWN);
    const token = AuthenticationStateUtility.getToken();
    if (token) {
      const user = jwt_decode(token);
      // TODO: Handle expired token
      setSessionState(SessionState.LOGGED_IN);
      setUser(user);
    } else {
      setSessionState(SessionState.LOGGED_OUT);
    }
  }, []);

  const authenticate = useCallback(async (userName, password) => {
    const result = await API.login({
      userName,
      password,
    });
    if (result && result.jwt) {
      setSessionState(SessionState.LOGGED_IN);
      AuthenticationStateUtility.setToken(result.jwt);
      const user = jwt_decode(result.jwt);
      setUser(user);
    } else {
      throw new Error('MISSING_JWT_TOKEN');
    }
  }, []);

  const logout = useCallback(() => {
    AuthenticationStateUtility.removeToken();
    localStorage.clear('token');
    setSessionState(SessionState.LOGGED_OUT);
    setUser(null);
  }, []);

  const register = useCallback(
    (requestData) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          await API.registration(requestData);
          resolve();
        } catch (err) {
          // TODO: Throw specific errors based on the issue: weak password, already used email, unknown error
          reject(err);
        }
      }),
    []
  );

  return (
    <Provider
      value={{
        sessionState,
        authenticate,
        logout,
        user,
        register,
      }}
    >
      {children}
    </Provider>
  );
};

export const AuthenticationConsumer = Consumer;

export const useAuthentication = () => useContext(AuthenticationContext);
