import React, { useCallback, useMemo, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core';
import { CustomSnackbarProvider } from './CustomSnackbarProvider';

export const DefaultSnackbarProvider = ({ children, ...rest }) => {
  const ref = useRef();
  const theme = useTheme();

  const handleOnClickDismiss = useCallback(
    (key) => ref.current.closeSnackbar(key),
    [ref]
  );

  const snackbarProviderProps = useMemo(
    () => ({
      maxSnack: 5,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      hideIconVariant: false,
      dense: false,
      preventDuplicate: true,
      persist: false,
      action: (key) => (
        <Button
          type="button"
          color="primary"
          size="small"
          onClick={() => handleOnClickDismiss(key)}
          style={{ color: theme.palette.common.white }}
        >
          Dismiss
        </Button>
      ),
    }),
    [handleOnClickDismiss, theme]
  );

  return (
    <SnackbarProvider ref={ref} {...snackbarProviderProps} {...rest}>
      <CustomSnackbarProvider>{children}</CustomSnackbarProvider>
    </SnackbarProvider>
  );
};
