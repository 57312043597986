import _ from 'lodash';

export class QueryUtilities {
  static buildQuery(filters) {
    let query = '';
    if (!_.isEmpty(filters)) {
      query += _.reduce(
        filters,
        (memo, value, key) => {
          if (_.isNil(value) || _.isEmpty(value)) {
            return memo;
          }
          const arr = value.map((v) => `${key}=${v}`);
          return `${memo}${!memo ? '?' : '&'}${arr.join('&')}`;
        },
        ''
      );
    }
    return query;
  }
}
