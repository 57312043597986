import axios from 'axios';
import { QueryUtilities } from '../Utils/QueryUtilities';

// TODO: divide API into separate files based on what resource they're using
class API {
  async post(url, data) {
    const results = await axios.post(url, data);
    return results.data;
  }

  async get(url) {
    const results = await axios.get(url);
    return results.data;
  }

  async delete(url) {
    const results = await axios.delete(url);
    return results.data;
  }
  async login(data) {
    const url = 'login/underwriter';
    return this.post(url, data);
  }

  async registration(data) {
    const url = `registration/underwriter`;
    return this.post(url, data);
  }

  async postEmailValidation(data) {
    const url = `registration/validation`;
    return this.post(url, data);
  }

  async requestPasswordReset(data) {
    const url = `registration/resetpassword/underwriter`;
    return this.post(url, data);
  }

  async setNewPassword(data) {
    const url = `registration/setNewPassword`;
    return this.post(url, data);
  }

  async saveGrower(data) {
    const url = `uwgrower/save`;
    return this.post(url, data);
  }

  async editGrower(data) {
    const url = `uwgrower/edit`;
    return this.post(url, data);
  }

  async getGrowers() {
    const url = `uwgrower/get`;
    return this.get(url);
  }

  async getGrower(id) {
    const url = `uwgrower/getById/${id}`;
    return this.get(url);
  }

  async getYieldCropSummary(growerId) {
    const url = `yield/getCropSummary/${growerId}`;
    return this.get(url);
  }

  async getYieldCropSummaryResult(data) {
    const url = `yield/getCropSummaryResult`;
    return this.post(url, data);
  }

  async getNASSData(data) {
    const url = `nass/generateData`;
    return this.post(url, data);
  }

  async getCoverageData(
    riskLayerOne,
    riskLayerTwo,
    calculatedStandardDeviation,
    aph
  ) {
    const url = `nass/getCoverageCalculation/?riskLayerOne=${riskLayerOne}&riskLayerTwo=${riskLayerTwo}&calculatedStandardDeviation=${calculatedStandardDeviation}&aph=${aph}`;
    return this.get(url);
  }

  async getCalculationDetailData(data) {
    const url = `nass/getCalculationDetailData`;
    return this.post(url, data);
  }

  async getAvailableYearsForNASS(data) {
    const url = `nass/getAvailableYears`;
    return this.post(url, data);
  }

  async getAvailableStatesForNASS(data) {
    const url = `nass/getAvailableStates`;
    return this.post(url, data);
  }

  async getAvailableCountiesForNASS(data) {
    const url = `nass/getAvailableCounties`;
    return this.post(url, data);
  }

  async saveFieldInfos(data) {
    const url = 'field/savefield';
    return this.post(url, data);
  }

  async editFieldInfos(data) {
    const url = `field/editfield`;
    return this.post(url, data);
  }

  async getFields(growerId) {
    const url = `field/getfields/${growerId}`;
    return this.get(url);
  }

  async getField(id) {
    const url = `field/getFieldById/${id}`;
    return this.get(url);
  }

  async saveYieldInfos(data) {
    const url = 'yield/saveyield';
    return axios.post(url, data);
  }

  async editYieldInfos(data) {
    const url = `yield/edityield`;
    return axios.post(url, data);
  }

  async getYields(growerId) {
    const url = `yield/getyields/${growerId}`;
    return this.get(url);
  }

  async deleteYield(id) {
    const url = `yield/delete/${id}`;
    return this.delete(url);
  }

  async saveCropModel(data) {
    const url = `cropModel/save`;
    return this.post(url, data);
  }

  async editCropModel(data) {
    const url = `cropModel/edit`;
    return this.post(url, data);
  }

  async getCropModels(growerId, includeFields) {
    // TODO: add query string builder hook
    const query = includeFields ? '?includeFields=true' : '';
    const url = `cropModel/getAll/${growerId}${query}`;
    return this.get(url);
  }

  async getGrowerYieldComparisons(growerId) {
    const url = `growerYieldComparison/getAll/${growerId}`;
    return this.get(url);
  }

  async getLossImpactDetails(data) {
    const url = `lossImpact/get`;
    return axios.post(url, data).then((res) => res.data);
  }

  async generateGrowerYieldComparisonDetails(
    growerId,
    cropModelId,
    fieldIds,
    years
  ) {
    const url = `growerYieldComparison/generate/details`;
    const query = QueryUtilities.buildQuery({
      growerId: [growerId],
      cropModelId: [cropModelId],
      fieldIds,
      years,
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }

  async generateGrowerYieldComparisonResults(data) {
    const url = `growerYieldComparison/generate/results`;
    return axios.post(url, data).then((res) => res.data);
  }

  async saveGrowerYieldComparison(data) {
    const url = `growerYieldComparison/save`;
    return axios.post(url, data).then((res) => res.data);
  }

  async deleteCropModel(cropModelId) {
    const url = `cropModel/delete/${cropModelId}`;
    return this.delete(url);
  }

  async getSavedCropModel(cropModelId) {
    const url = `cropModel/get/${cropModelId}`;
    return this.get(url);
  }

  async deleteGrowerYieldComparison(yieldComparisonId) {
    const url = `growerYieldComparison/delete/${yieldComparisonId}`;
    return this.delete(url);
  }

  async fetchGrowerYieldComparison(yieldComparisonId) {
    const url = `growerYieldComparison/get/${yieldComparisonId}`;
    return this.get(url);
  }

  async editGrowerYieldComparison(data) {
    const url = `growerYieldComparison/edit`;
    return this.post(url, data);
  }

  async getOptionModels(growerId) {
    const url = `optionModel/getAll/${growerId}`;
    return this.get(url);
  }

  async getContractingGroups(growerId) {
    const url = `coverageAndContractingGroup/get/${growerId}`;
    return this.get(url);
  }

  async getOptionModel(id) {
    const url = `optionModel/get/${id}`;
    return axios.get(url).then((res) => res.data);
  }

  async saveOptionModel(data) {
    const url = 'optionModel/save';
    return axios.post(url, data).then((res) => res.data);
  }

  async editOptionModel(data) {
    const url = `optionModel/edit`;
    return axios.post(url, data).then((res) => res.data);
  }

  async calculateOptionModel(data) {
    const url = `optionModel/calculate`;
    return axios.post(url, data).then((res) => res.data);
  }

  async saveContractingGroup(data) {
    const url = `coverageAndContractingGroup/saveGroup`;
    return this.post(url, data);
  }

  async getInitialCoverageCostIllustration(performanceId, type) {
    const url = `coverageCostIllustration/get/initial`;
    const query = QueryUtilities.buildQuery({
      performanceId: [performanceId],
      type: [type],
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }

  async editContractingGroup(data) {
    const url = `coverageAndContractingGroup/editGroup`;
    return this.post(url, data);
  }

  async getContractingGroupById(contractingGroupId) {
    const url = `coverageAndContractingGroup/getGroupById/${contractingGroupId}`;
    return this.get(url);
  }

  async calculateCoverageCostIllustration(data) {
    const url = `coverageCostIllustration/calculate`;
    return axios.post(url, data).then((res) => res.data);
  }

  async calculateMarketed(data) {
    const url = `marketed/calculate`;
    return axios.post(url, data).then((res) => res.data);
  }

  async calculateContracted(data) {
    const url = `contracted/calculate`;
    return axios.post(url, data).then((res) => res.data);
  }

  async saveCoverageAndContractingCalculation(data) {
    const url = 'coverageAndContractingCalculation/save';
    return axios.post(url, data).then((res) => res.data);
  }

  async getCoverageAndContractingCalculation(
    coverageAndContractingCalculationId
  ) {
    const url = 'coverageAndContractingCalculation/get';
    const query = QueryUtilities.buildQuery({
      coverageAndContractingCalculationId: [
        coverageAndContractingCalculationId,
      ],
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }

  async editCoverageAndContractingCalculation(data) {
    const url = 'coverageAndContractingCalculation/edit';
    return axios.post(url, data).then((res) => res.data);
  }

  async getContractingCalculationsByGroup(uwGrowerId, contractingGroupId) {
    const url = `coverageAndContractingCalculation/getAll`;
    const query = QueryUtilities.buildQuery({
      uwGrowerId: [uwGrowerId],
      coverageAndContractingGroupId: [contractingGroupId],
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }

  async generatePortfolioPerformanceDetails(data) {
    const url = `portfolioPerformance/generate/details`;
    return axios.post(url, data).then((res) => res.data);
  }

  async generatePortfolioPerformanceResults(data) {
    const url = `portfolioPerformance/generate/results`;
    return axios.post(url, data).then((res) => res.data);
  }

  async getPortfolioPerformance(portfolioPerformanceId) {
    const url = `portfolioPerformance/get`;
    const query = QueryUtilities.buildQuery({
      portfolioPerformanceId: [portfolioPerformanceId],
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }

  async deletePortfolioPerformance(performanceId) {
    const url = `portfolioPerformance/delete/${performanceId}`;
    return this.delete(url);
  }

  async getPortfolioPerformances(uwGrowerId) {
    const url = `portfolioPerformance/getAll`;
    const query = QueryUtilities.buildQuery({
      uwGrowerId: [uwGrowerId],
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }

  async savePortfolioPerformance(data) {
    const url = `portfolioPerformance/save`;
    return axios.post(url, data).then((res) => res.data);
  }

  async editPortfolioPerformance(data) {
    const url = `portfolioPerformance/edit`;
    return axios.post(url, data).then((res) => res.data);
  }

  async getPerformances(uwGrowerId) {
    const url = `performance/getAll`;
    const query = QueryUtilities.buildQuery({
      uwGrowerId: [uwGrowerId],
    });
    return axios.get(`${url}${query}`).then((res) => res.data);
  }
}
export default new API();
