import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { configureAxios } from './Configuration/AxiosConfiguration';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  AuthenticationConsumer,
  AuthenticationProvider,
} from './Context/AuthenticationContext';
import { DialogProvider } from './Context/DialogContext';
import { createTheme } from './Theme';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './libs/i18next';
import { ModalProvider } from './Context/ModalProvider/ModalProvider';
import { configureDayjs } from './Configuration/DayjsConfiguration';
import ScrollToTop from './Components/Common/ScrollToTop';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { DefaultSnackbarProvider as SnackbarProvider } from './Context/SnackbarProvider/DefaultSnackbarProvider';

configureAxios();
configureDayjs();

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Router>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthenticationProvider>
              <AuthenticationConsumer>
                {({ sessionState }) => (
                  <ModalProvider>
                    <DialogProvider>
                      <SnackbarProvider>
                        <App sessionState={sessionState} />
                      </SnackbarProvider>
                    </DialogProvider>
                  </ModalProvider>
                )}
              </AuthenticationConsumer>
            </AuthenticationProvider>
          </ThemeProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
