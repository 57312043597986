import Common from './Common';
import GrowerSummary from './GrowerSummary';
import CropModel from './CropModel';
import GrowerYieldComparison from './GrowerYieldComparison';
import HistoricalYield from './HistoricalYield';
import FieldInfo from './FieldInfo';
import Grower from './Grower';
import CoverageAndContractingCalculation from './CoverageAndContractingCalculation';
import OptionModel from './OptionModel';
import Contracting from './Contracting';
import PortfolioPerformance from './PortfolioPerformance';

export default {
  common: Common,
  growerSummary: GrowerSummary,
  cropModel: CropModel,
  historicalYield: HistoricalYield,
  growerYieldComparison: GrowerYieldComparison,
  fieldInfo: FieldInfo,
  grower: Grower,
  coverageAndContracting: CoverageAndContractingCalculation,
  optionModel: OptionModel,
  contracting: Contracting,
  portfolioPerformance: PortfolioPerformance,
};
