import React from 'react';
import Button from '@material-ui/core/Button';
import { default as MaterialDialog } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';

export default function Dialog(props) {
  const handleClose = () => {
    props.setIsVisible(false);
  };

  const generateButtons = () => {
    return props.dialogButtons.map((x, idx) => {
      return (
        <Button
          key={idx}
          color={x.color}
          variant={x.variant}
          className={x.className}
          onClick={() => x.action()}
        >
          {x.title}
        </Button>
      );
    });
  };

  return (
    <Box>
      <MaterialDialog
        open={props.isVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{generateButtons()}</DialogActions>
      </MaterialDialog>
    </Box>
  );
}
