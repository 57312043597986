export default {
  FIELD_INFO_TITLE: 'Field Information',
  OWNED_LEASED: 'Owned/Leased',
  OWNED: 'Owned',
  LEASED: 'Leased',
  SHARE: 'Share',
  CONTRACTED: 'Contracted',
  NOT_CONTRACTED: 'Not Contracted',
  PRACTICE_TYPE: 'Practice Type',
  DRYLAND: 'Dryland',
  IRRIGATED: 'Irrigated',
};
