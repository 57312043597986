/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';
import Dialog from '../Components/Dialog';

const DialogContext = React.createContext({
  isVisible: false,
  displayDialog: (title, message, buttons, variant, color, className) => {},
  dismissDialog: () => {},
});

const { Provider, Consumer } = DialogContext;

export const DialogProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogButtons, setDialogButtons] = useState([]);

  const displayDialog = useCallback(
    (title, message, buttons, variant, color, className) => {
      setDialogTitle(title);
      setDialogMessage(message);
      setIsVisible(true);
      setDialogButtons(buttons);
    },
    []
  );

  const dismissDialog = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <Provider
      value={{
        isVisible,
        displayDialog,
        dismissDialog,
      }}
    >
      {children}
      <Dialog
        dialogButtons={dialogButtons}
        dialogMessage={dialogMessage}
        dialogTitle={dialogTitle}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </Provider>
  );
};

export const DialogConsumer = Consumer;

export const useDialog = () => useContext(DialogContext);
