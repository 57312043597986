import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import SetNewPasswordPage from './SetNewPasswordPage';
import { EmailVerificationPage } from './EmailVerificationPage';
import PublicRoutes from '../Constants/PublicRoutes';

export const LoggedOutView = () => {
  return (
    <Switch>
      <Route path={PublicRoutes.Login} component={LoginPage} />
      <Route path={PublicRoutes.Registration} component={RegistrationPage} />
      <Route
        path={PublicRoutes.ForgotPassword}
        component={ForgotPasswordPage}
      />
      <Route path={PublicRoutes.ResetPassword} component={SetNewPasswordPage} />
      <Route
        path={PublicRoutes.Confirmation}
        component={EmailVerificationPage}
      />
      <Route path="**">
        <Redirect to={PublicRoutes.Login} />
      </Route>
    </Switch>
  );
};
