export default {
  CALL_PRICE: 'Call Price',
  CROP: 'Crop',
  D1: 'D1',
  D2: 'D2',
  END_DATE: 'End Date',
  NAME: 'Name',
  MONTHS: 'Months',
  OPTION_MODEL: 'Option Model',
  OPTION_MODELS: 'Option models',
  PRICING_DATE: 'Pricing Date',
  PUT_PRICE: 'Put Price',
  REFERENCE_PRICE_LABEL: 'Reference (current) Price',
  RISK_FREE_RATE: 'Risk Free Rate',
  STRIKE_PRICE: 'Strike Price',
  SIGMA_LABEL: 'Sigma (variance)',
  TIME_LABEL: 'Time (years)',
  TRADING_DAYS: 'Trading Days',
  OPTION_MODEL_FETCH_ERROR: 'Failed to fetch Option Model',
  OPTION_MODEL_CALCULATION_ERROR: 'Failed to calculate Option Model',
};
