import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUSTranslations from './translations/en-US';
import dayjs from 'dayjs';

i18n.use(initReactI18next).init({
  load: 'currentOnly',
  lng: 'en-US',
  fallbackLng: 'en-US',
  supportedLngs: ['en-US'],
  // Translation jsons are flat. Modularization is achieved via namespaces instead
  keySeparator: false,
  react: {
    nsMode: 'fallback',
    useSuspense: true,
  },
  interpolation: { escapeValue: false },
  debug: process.env.NODE_ENV === 'development',
  // not needed for react as it escapes by default
  resources: {
    'en-US': enUSTranslations,
  },
});

i18n.on('languageChanged', (lng) => {
  console.log(`Locale ${lng} set for date format`);
  dayjs.locale(lng);
});

export default i18n;
