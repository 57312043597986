const PrivateRoutes = {
  Home: '/home',
  GrowerSummary: '/growers/:id/summary/:activeTab?',
  GrowerFieldSummary: '/growers/:id/field-summary',
  GrowerHistoricalYieldSummary: '/growers/:id/historical-yield-summary',
  GrowerYieldComparison: '/growers/:id/grower-yield-comparison',
  CreateGrowerYieldComparison: '/growers/:id/grower-yield-comparison/create',
  EditGrowerYieldComparison:
    '/growers/:id/grower-yield-comparison/:growerYieldComparisonId/edit',
  GrowerCreateCropModel: '/growers/:id/crop-models/create',
  GrowerEditCropModel: '/growers/:id/crop-models/:cropModelId/edit',
  CreateCoverageAndContractingCalculation:
    '/growers/:id/coverage-and-contracting-group/:contractingGroupId/edit/coverage-and-contracting-calculation/create',
  EditCoverageAndContractingCalculation:
    '/growers/:id/coverage-and-contracting-group/:contractingGroupId/edit/coverage-and-contracting-calculation/:coverageAndContractingCalculationId/edit',
  GrowerCreateOptionModel: '/growers/:id/option-models/create',
  GrowerEditOptionModel: '/growers/:id/option-models/:optionModelId/edit',
  EditCoverageAndContractingGroup:
    '/growers/:id/coverage-and-contracting-group/:contractingGroupId/edit',
  CreatePortfolioPerformance: '/growers/:id/portfolio-performances/create',
  EditPortfolioPerformance:
    '/growers/:id/portfolio-performances/:portfolioPerformanceId/edit',
};
export default PrivateRoutes;
