import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
  FormControl,
} from '@material-ui/core';
import clsx from 'clsx';
import { Box, Grid, Link } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '../Context/AuthenticationContext';

import { useStyles } from './LoginPageTheme';
import PublicRoutes from '../Constants/PublicRoutes';
import { Alert } from '@material-ui/lab';

export default function LoginPage() {
  const classes = useStyles();
  const location = useLocation();
  const [values, setValues] = React.useState({
    password: '',
    userName: '',
    showPassword: false,
  });
  const [loginError, setLoginError] = useState('');
  const [cameFromEmailVerification, setCameFromEmailVerification] =
    useState(false);
  const [cameFromResetPassword, setCameFromResetPassword] = useState(false);
  const { authenticate } = useAuthentication();
  const login = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await authenticate(values.userName, values.password);
      } catch (err) {
        if (err.response) {
          setLoginError(err.response.data);
        } else {
          setLoginError(err.message);
        }
      }
    },
    [authenticate, values]
  );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    if (location.state && location.state.isFromEmailVerification) {
      setCameFromEmailVerification(true);
    }

    if (location.state && location.state.isFromResetPassword) {
      setCameFromResetPassword(true);
    }
  }, [location]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <Box className={classes.welcome}> WELCOME TO ANALYST</Box>
        </Typography>

        <form className={classes.form} noValidate>
          <TextField
            id="outlined adornment"
            label="User name"
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            style={{
              width: 380,
              margin: 10,
              backgroundColor: 'white',
              borderRadius: 5,
              marginBottom: 25,
              boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
            }}
            name="userName"
            value={values.userName}
            onChange={handleChange('userName')}
          />
          <FormControl>
            <TextField
              className={clsx(classes.margin, classes.textField)}
              id="outlined-adornment-password"
              variant="outlined"
              label="Password"
              style={{
                width: 380,
                marginLeft: 10,
                backgroundColor: 'white',
                borderRadius: 5,
                boxShadow: '0px 0px 20px rgba(23, 50, 97, 0.2)',
              }}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
            />
          </FormControl>
          {loginError !== '' && <Alert severity="error">{loginError}</Alert>}
          {cameFromEmailVerification && (
            <Alert severity="success">
              Your email has been validated successfully. Please sign in to
              continue.
            </Alert>
          )}

          {cameFromResetPassword && (
            <Alert severity="success">
              Your password has been reset successfully. Please sign in to
              continue.
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{
              width: 380,
              height: 40,
              margin: 10,
              marginTop: 25,
            }}
            onClick={login}
          >
            Log in
          </Button>
          <div style={{ padding: 11 }}>
            <Grid container>
              <Grid item xs style={{ textAlign: 'left' }}>
                <Link
                  href={PublicRoutes.ForgotPassword}
                  variant="body2"
                  className={classes.links}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href={PublicRoutes.Registration}
                  variant="body2"
                  className={classes.links}
                >
                  New user? Sign up for account
                </Link>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </Container>
  );
}
