import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const muiThemeOverrides = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%',
          fontSize: '14px',
        },
        body: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%',
          margin: 0,
          'font-family':
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
        a: {
          textDecoration: 'none',
        },
        code: {
          'font-family':
            'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
        },
        '#root': {
          height: '100%',
          width: '100%',
        },
      },
    },
  },
};

// TODO: switch from px to rem units
const defaultTheme = {
  palette: {
    primary: {
      main: '#081f2c',
      light: '#FFFFFF',
      dark: '#003b4c',
      contrastText: grey['50'],
    },
    secondary: {
      main: '#91b6bb',
      contrastText: grey['50'],
      light: '#decc62',
      dark: '#c5b683',
    },
    error: {
      main: '#d32f2f',
    },
    inputShadow: {
      main: 'rgba(23, 50, 97, 0.2)',
    },
  },
  typography: {
    fontFamily: 'Cabin',
    color: '#081f2c',
    h5: {
      fontSize: '24px',
      fontWeight: 600,
      fontFamily: 'Cabin',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: 600,
      fontFamily: 'Cabin',
    },
    subtitle2: {
      fontSize: '20px',
      fontWeight: 600,
      fontFamily: 'Cabin',
    },
  },
};

// TODO: provide dark/light mode as param
export const createTheme = () =>
  createMuiTheme({
    ...muiThemeOverrides,
    ...defaultTheme,
  });
