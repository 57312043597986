import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: theme.palette.primary.light,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: 'Cabin',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    textTransform: 'none',
    fontSize: 16,
  },

  welcome: {
    fontFamily: 'Cabin',
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    marginTop: 10,
  },
  links: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));
