import React from 'react';
import logo from '../Assets/vaneLogo.png';
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Container,
} from '@material-ui/core';
import {
  SessionState,
  useAuthentication,
} from '../Context/AuthenticationContext';
import { useStyles } from './HeaderTheme';
import { useHistory } from 'react-router-dom';
import PrivateRoutes from '../Constants/PrivateRoutes';

export default function Header(props) {
  const classes = useStyles();

  const { logout } = useAuthentication();
  const history = useHistory();
  const headerLinks = [
    {
      title: `Home`,
      action: () => {
        history.push(PrivateRoutes.Home);
      },
    },
    {
      title: `Logout`,
      action: () => {
        logout();
      },
    },
  ];

  const generateNavLinks = () => {
    return headerLinks.map(({ title, action }) => (
      <ListItem
        key={title}
        className={classes.linkText}
        button
        onClick={action}
      >
        <ListItemText primary={title} />
      </ListItem>
    ));
  };

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar>
        <img alt="vane_logo" src={logo} className={classes.logo} />
        {props.sessionState === SessionState.LOGGED_IN && (
          <Container maxWidth="xl" className={classes.headerDisplayFlex}>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navDisplayFlex}
            >
              {generateNavLinks()}
            </List>
          </Container>
        )}
      </Toolbar>
    </AppBar>
  );
}
