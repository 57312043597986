import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';

const CustomSnackbarProviderContext = createContext({
  // eslint-disable-next-line no-unused-vars
  showDefault: (message) => {},
  // eslint-disable-next-line no-unused-vars
  showError: (message) => {},
  // eslint-disable-next-line no-unused-vars
  showSuccess: (message) => {},
  // eslint-disable-next-line no-unused-vars
  showWarning: (message) => {},
  // eslint-disable-next-line no-unused-vars
  showInfo: (message) => {},
});

const CustomSnackbarProvider = ({ children, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = useCallback(
    (message, variant) => enqueueSnackbar(message, { variant }),
    [enqueueSnackbar]
  );

  const value = useMemo(
    () => ({
      showDefault: (message) => showSnackbar(message, 'default'),
      showError: (message) => showSnackbar(message, 'error'),
      showSuccess: (message) => showSnackbar(message, 'success'),
      showWarning: (message) => showSnackbar(message, 'warning'),
      showInfo: (message) => showSnackbar(message, 'info'),
    }),
    [showSnackbar]
  );

  return (
    <CustomSnackbarProviderContext.Provider value={value} {...rest}>
      {children}
    </CustomSnackbarProviderContext.Provider>
  );
};

const useCustomSnackbar = () => useContext(CustomSnackbarProviderContext);

export { CustomSnackbarProvider, useCustomSnackbar };
