import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useStyles } from './LoadingPageTheme';

/**
 * Common component for a loading indicator page that covers the whole page
 */
export const LoadingPage = () => {
  const styles = useStyles();
  return (
    <Box className={styles.loader}>
      <CircularProgress />
    </Box>
  );
};
