import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '200px',
    minHeight: '200px',
    boxShadow: 'none',
  },
  icon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));
