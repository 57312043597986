export default {
  AVERAGE_YIELD_BUSHELS_ACRES: 'Average Yield (bushels/acres)',
  COVERAGE_1: 'Coverage 1',
  COVERAGE_2: 'Coverage 2',
  CROP_MODELS: 'Crop Models',
  FIELD_SUMMARY_INFORMATION: 'Field Summary Information',
  GROWER_ADDRESS: 'Grower Address',
  GROWER_NAME: 'Grower Name',
  GROWER_YIELD_COMPARISON: 'Grower Performance',
  GROWER_SUMMARY_INFORMATION: 'Grower Summary Information',
  HISTORICAL_YIELD_SUMMARY: 'Historical Yield Summary',
  NAME: 'Name',
  UPDATED_AT: 'Updated At',
  VIEW: 'View',
  YIELD_BUSHELS_ACRES: 'Yield (bushels / acres)',
  NO_FIELD_MESSAGE:
    'Field Summary Information required to enter Historical Yield Summary data',
  CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this crop model?',
  STATUS_DELETE_TITLE: 'Delete Rejected',
  CONFIRM_DELETE_COMPARISON_MESSAGE:
    'Are you sure you want to delete this yield comparison message?',
  CONFIRM_DELETE_PORTFOLIO_PERFORMANCE_MESSAGE:
    'Are you sure you want to delete this portfolio performance?',
  FIELDS: 'Fields',
  MODELS: 'Models',
  PRICING: 'Pricing',
};
