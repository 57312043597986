import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: theme.palette.primary.main,
    borderColor: '#4d6d84 !important',
    fontFamily: 'Cabin',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'bold',
  },
  signUp: {
    fontFamily: 'Cabin',
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
}));
