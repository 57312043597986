import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './ModalProviderTheme';

const ModalContext = React.createContext({
  // eslint-disable-next-line no-unused-vars
  showModal: (content, maxWidth) => {},
  closeModal: () => {},
});

const MAX_WIDTH_DEFAULT = 'lg';

const Modal = ({ open, closeModal, maxWidth, children }) => {
  const styles = useStyles();
  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      fullWidth
      maxWidth={maxWidth}
      scroll="paper"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeModal();
        }
      }}
    >
      <div
        role="button"
        onClick={() => closeModal()}
        onKeyDown={() => closeModal()}
        tabIndex={0}
      >
        <IconButton size="small" color="primary" className={styles.icon}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      {children}
    </Dialog>
  );
};

const ModalProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [maxWidth, setMaxWidth] = useState(MAX_WIDTH_DEFAULT);
  const [content, setContent] = useState(null);

  const showModal = (modalContent, modalMaxWidth) => {
    setContent(modalContent);
    const width = modalMaxWidth || MAX_WIDTH_DEFAULT;
    setMaxWidth(width);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, closeModal }} {...props}>
      {props.children}
      <Modal open={open} closeModal={closeModal} maxWidth={maxWidth}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
};

const useModal = () => React.useContext(ModalContext);

export { ModalProvider, useModal };
