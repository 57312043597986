import React, { useEffect, useState } from 'react';
import API from '../API/API';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './EmailVerificationPageTheme';
import PublicRoutes from '../Constants/PublicRoutes';

export function EmailVerificationPage() {
  const [isThereValidationError, setIsThereValidationError] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(location.search);

      try {
        await API.postEmailValidation({
          token: query.get('token'),
          email: query.get('email'),
        });
        history.replace({
          pathname: PublicRoutes.Login,
          state: { isFromEmailVerification: true },
        });
      } catch (ex) {
        setIsThereValidationError(true);
      }
    })();
  }, [history, location.search]);

  const getMessage = () => {
    return isThereValidationError
      ? 'Error during email verification, please try again...'
      : 'Email verification in progress...';
  };
  return (
    <div className={classes.container}>
      <p>{getMessage()}</p>
    </div>
  );
}
