export default {
  PORTFOLIO_PERFORMANCE: 'Portfolio Performance',
  PORTFOLIO: 'Portfolio',
  ADD: 'Add',
  CROP_MODEL: 'Crop Model',
  FIELDS: 'Fields',
  YEARS: 'Years',
  MULTIPLE: 'Multiple',
  SELECTED: 'Selected',
  ADJUSTED_APH: 'Adjusted APH',
  ADJUSTED_YIELD: 'Adjusted Yield',
  BASIC_YIELD: 'Basic Yield',
  CALCULATE: 'Calculate',
  CAT: 'CAT',
  CAT_COVERAGE: 'Cat Coverage',
  COMPARE: 'Compare',
  COUNTY_DT: 'County-DT',
  CV: 'CV',
  DETAILED_DATA: 'Detailed Data',
  DETAILED_DATA_CHANGED_WARNING:
    'Detailed Data has changed. Please press $t(CALCULATE) button to recalculate results!',
  GROWER: 'Grower',
  MAX_COVERAGE: 'Max Coverage',
  MAX_YIELD: 'Max Yield',
  NAME: 'Name',
  RESULTS: 'Results',
  SAVE: 'Save',
  SD: 'SD',
  OTHER1: 'Other-1',
  YEAR: 'Year',
  COUNTIES: 'Counties',
  COUNTIES_DT: 'Counties-DT',
  PORTFOLIO_PERFORMANCE_DETAILS_FETCH_ERROR:
    'Failed to fetch details for portfolio performance',
  PERFORMANCE_RATIO: 'Performance Ratio',
  PORTFOLIO_PERFORMANCE_RESULT_FETCH_ERROR:
    'Failed to fetch results for portfolio performance',
  PERFORMANCE_DETAIL: 'Performace Detail',
};
