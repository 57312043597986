import './App.css';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { SessionState } from './Context/AuthenticationContext';
import Header from './Components/Header';
import { LoadingPage } from './Components/Common/LoadingPage/LoadingPage';
import { LoggedOutView } from './Pages/LoggedOutView';

const LoggedInView = lazy(() => import(`./Pages/LoggedInView`));

export const App = ({ sessionState }) => {
  if (sessionState === SessionState.UNKNOWN) {
    return <LoadingPage />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <div className="App">
        <Header sessionState={sessionState} />
        <Switch>
          {sessionState === SessionState.LOGGED_IN ? (
            <LoggedInView />
          ) : (
            <LoggedOutView />
          )}
        </Switch>
      </div>
    </Suspense>
  );
};
