export default {
  CREATE_GROWER: 'Create Grower',
  EDIT_GROWER: 'Edit Grower',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PRIMARY_ADDRESS: 'Primary Address',
  SECONDARY_ADDRESS: 'Secondary Address',
  CITY: 'City',
  ZIP_CODE: 'Zip Code',
  VANE_MYJD_PARTNER: 'Vane MyJD partner?',
  ORGANIZATION_ID: 'Organization Id',
};
