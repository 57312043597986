export default {
  ADJUSTED_APH: 'Adjusted APH',
  ADJUSTED_YIELD: 'Adjusted Yield',
  BASIC_YIELD: 'Basic Yield',
  CALCULATE: 'Calculate',
  CAT: 'CAT',
  CAT_COVERAGE: 'Cat Coverage',
  CROP_MODEL: 'Crop Model',
  COMPARE: 'Compare',
  COUNTY_DT: 'County-DT',
  CV: 'CV',
  DETAILED_DATA: 'Detailed Data',
  DETAILED_DATA_CHANGED_WARNING:
    'Detailed Data has changed. Please press $t(CALCULATE) button to recalculate results!',
  FIELDS: 'Fields',
  GROWER: 'Grower',
  GROWER_YIELD_COMPARISON: 'Grower Performance',
  MAX_COVERAGE: 'Max Coverage',
  MAX_YIELD: 'Max Yield',
  NAME: 'Name',
  RESULTS: 'Results',
  SAVE: 'Save',
  SD: 'SD',
  SELECTED: 'Selected',
  OTHER1: 'Other-1',
  YEAR: 'Year',
  YEARS: 'Years',
};
