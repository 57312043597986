export default {
  ACTIONS: 'Actions',
  ADD: 'Add',
  ALL: 'All',
  CALCULATE: 'Calculate',
  EDIT: 'Edit',
  FILTER: 'Filter',
  SUBMIT: 'Submit',
  YES: 'Yes',
  CANCEL: 'Cancel',
  CORN: 'Corn',
  CROP: 'Crop',
  COUNTY: 'County',
  YEAR: 'Year',
  ACRES: 'Acres',
  SOYBEANS: 'Soybeans',
  STATE: 'State',
  DELETE: 'Delete',
  CONFIRM_DELETE_TITLE: 'Confirm delete',
  NOT_AVAILABLE_SHORT: 'N/A',
  FIELD_NAME: 'Field Name',
  PRICING_DATE: 'Pricing Date',
  END_DATE: 'End Date',
  THIS_FIELD_IS_REQUIRED: 'This field is required.',
  NAME: 'Name',
  VIEW: 'View',
  BACK: 'Back',
  SAVE: 'Save',
  RECAST: 'Recast',
  DISCARD: 'Discard',
  UNSAVED_CHANGES_WARNING_TITLE: 'Unsaved Changes',
  UNSAVED_CHANGES_WARNING_MESSAGE:
    'You have unsaved changes. Do you want to discard them?',
  UNEXPECTED_ERROR: 'An unexpected error occurred',
};
