export default {
  CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this field history?',
  ACRES_PLANTED: 'Acres Planted',
  TOTAL_BU_DRY: 'Total bu(Dry)',
  AVG_YIELD_DRY: 'Avg Yield(Dry)',
  ACTIONS: 'Actions',
  NOTES: 'Notes',
  HISTORICAL_YIELD_SUMMARY: 'Historical Yield Summary',
  DETAILED_DATA: 'Detailed Data',
  ADD: 'Add',
  PERCENT_OF_ACRES: '% of Acres',
  ACRES: 'Acres',
  PRODUCTION: 'Production',
  YIELD: 'Yield',
  INCL: 'Incl.',
  CROP_SUMMARY: 'Crop Summary',
  BASIC_AVERAGE: 'Basic Average',
  ADJUSTED_AVERAGE: 'Adjusted Average',
  COVERAGE_PERCENTAGE: 'Coverage %',
  MAX_COVERAGE: 'Max Coverage',
  RECALCULATE: 'Recalculate',
  CORN: 'Corn',
  SOYBEANS: 'Soybeans',
  NOTE: 'Note',
};
