export default {
  BU: 'Bu',
  COVERAGE_AND_CONTRACTING_CALCULATION: 'Coverage and Contracting Calculation',
  PERFORMANCE_SELECT_TITLE: 'Performance',
  CROP: 'Crop',
  STATE: 'State',
  COVERAGE_COST_ILLUSTRATION: 'Coverage Cost-Illustration',
  CONTRACTED: 'Contracted',
  MARKETED: 'Marketed',
  LOSS_IMPACT: 'Loss Impact',
  CONFIRM_CHANGE_TITLE: 'Confirm change',
  CONFIG_CHANGE_MESSAGE:
    'Changing the Grower Performance will reset all the existing date and results of the Calculation. Are you sure?',
  CHANGE: 'Change',
  INSURED_ACRES: 'Insured Acres',
  PP_LOSS: 'PP Loss',
  PP_LOSS_RATIO: 'PP Loss Ratio',
  RP_LOSS: 'RP Loss',
  RP_LOSS_RATIO: 'RP Loss Ratio',
  LOSS_IMPACT_DETAILED_DATA: 'Detailed Data',
  LOSS_IMPACT_SUMMARY: 'Summary',
  EXPECTED: 'Expected',
  SD: 'SD',
  MAX_COVERAGE: 'Max Coverage',
  CAT_COVERAGE: 'Cat Coverage',
  CALCULATION_DATA: 'Calculation Data',
  DETAILED_DATA: 'Detailed Data',
  RECASTED_YIELD: 'Recasted Yield',
  PERIOD: 'Period',
  AVERAGE: 'Average',
  MAX: 'Max',
  YEAR_SMALL: 'year',
  INPUTS: 'Inputs',
  CONTRACTED_APH_TARGET: 'Contracted APH Target',
  CONTRACTED_BU: 'Contracted bu',
  PRICE: 'Price',
  REVENUE: 'Revenue',
  LIMIT_RATE: 'Limit Rate',
  PRICE_PER_ACRE: 'Price / Acre',
  CONTRACTED_COVERAGE: 'Contracted Coverage',
  LAYER_1: 'Layer 1',
  LAYER_2: 'Layer 2',
  TOTAL: 'Total',
  TOTAL_RISK: 'Total Risk',
  BUSHELS: 'Bushels',
  RISK_RATE_BU: 'Risk Rate (bu)',
  RISK_RATE_PERCENT: 'Risk Rate (%)',
  LOSS_PICK: 'Loss Pick',
  COST_PER_ACRE: 'Cost / Acre',
  MARKETING_COVERAGE: 'Marketing Coverage',
  OPTION_CALCULATION: 'Option Calculation',
  OPTION_MODEL: 'Option Model',
  TRADING_DAYS: 'Trading Days',
  MONTHS: 'Months',
  TIME_LABEL: 'Time (years)',
  STOCK_PRICE: 'Stock Price',
  STRIKE_PRICE: 'Strike Price',
  RISK_FREE_RATE: 'Risk Free Rate',
  SIGMA_LABEL: 'Sigma (variance)',
  CALCULATIONS: 'Calculations',
  D1: 'D1',
  D2: 'D2',
  ANALYTIC: 'Analytic',
  CALL_PRICE: 'Call Price',
  PUT_PRICE: 'Put Price',
  STRIKE_PRICE_CHANGED_WARNING: 'Strike Price changed. Please recalculate!',
  MARKETING_YIELD: 'Marketing Yield',
  CONTRACTED_YIELD: 'Contracted Yield',
  BU_COVERED: 'Bu Covered',
  REF_PRICE: 'Ref Price',
  PUT_PRICE_PER_BU: 'Put Price (per bu)',
  PER_ACRE: 'Per Acre',
  DRIVERS: 'Drivers',
  YIELD_DIFFERENCE: 'Yield Difference',
  OPTION_D1: 'Option d1',
  OPTION_D2: 'Option d2',
  BU_EXPOSED: 'Bu. Exposed',
  PUT_COST: 'Put Cost',
  NUMBER_OF_YEARS_IMPACTED: 'Number of years impacted',
  PER_BU: 'Per bu',
  PER_ACRES: 'Per Acres',
  FREQUENCY: 'Frequency',
  SEVERITY: 'Severity',
  COVERAGE_LIMIT: 'Coverage Limit',
  CALL_PRICE_PER_BU: 'Call Price (per bu)',
  RECAST: 'Recast',
  LOSS_IMPACT_FETCH_ERROR: 'Failed to fetch loss impact data',
  PP_ADJ: 'PP Adj',
  CALL_COST: 'Call Cost',
  PP_LOSS_PERIOD_SELECTION: 'PP Loss Period Selection',
  ACTUAL: 'Actual',
  CAT: 'CAT',
  DETAILED_DATA_CHANGED_WARNING:
    'Detailed Data has changed. Please press $t(CALCULATE) button to recalculate results!',
  PERFORMANCE_FETCH_ERROR: 'Failed to fetch performance data',
  COVERAGE_COST_ILLUSTRATION_FETCH_ERROR:
    'Failed to fetch coverage cost-illustration data',
  CONTRACTED_FETCH_ERROR: 'Failed to fetch contracted data',
  MARKETED_FETCH_ERROR: 'Failed to fetch marketed data',
  RISK_LAYER_ONE: 'Risk Layer One',
  RISK_LAYER_TWO: 'Risk Layer Two',
  COVERAGE_AND_CONTRACTING_CALCULATION_FETCH_ERROR:
    'Failed to fetch coverage and contracting calculation data',
  MULTIPLE: 'Multiple',
};
