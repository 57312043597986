export default {
  ALL: 'All',
  APH: 'APH',
  AG_DISTRICT: 'Ag District',
  ACTUAL_VALUE: 'Actual value',
  APPLY: 'Apply',
  CROP_MODEL: 'Crop Model',
  COVERAGE: 'Coverage',
  COVERAGE_CHANGE_MESSAGE: 'Coverage threshold changed, please recalculate',
  COMMODITY: 'Commodity',
  DETREND: 'Detrend',
  DETREND_CHANGE_MESSAGE: 'Detrend changed, please recalculate',
  DETAILED_DATA: 'Detailed Data',
  FILTER: 'Filter',
  INTERSECT: 'Intersect',
  STATE: 'State',
  RESULTS: 'Results',
  RECALCULATE: 'Recalculate',
  SLOPE: 'Slope',
  STANDARD_DEVIATION: 'Standard Deviation',
  DEVIATION: 'Deviation',
  TREND_LINE: 'Trend Line',
  VALUE: 'Value',
  VARIANCE: 'Variance',
  YEARS: 'Years',
  YEAR: 'Year',
  SAVE: 'Save',
  MINIMUM_YEAR_ERROR_MESSAGE: 'A minimum of two years must be selected.',
  RISK_LAYER_ONE: 'Risk Layer One',
  RISK_LAYER_TWO: 'Risk Layer Two',
};
